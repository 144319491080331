import React from "react"
import Layout from "../components/layout"
import ImageContainer from "../components/imageContainer"
import Team1 from "../images/team1.png"
import Team2 from "../images/team2.png"
import Team3 from "../images/team3.png"
import Team4 from "../images/team4.png"
import Process from "../images/process.png"
import Design from "../images/design.png"
import Engineering from "../images/engineering.png"
import Growth from "../images/growth.png"
import Linkedin from "../images/in.png"
import Shape2 from "../images/shape2.png"
import BgAbout from "../components/bgAbout"

const Style = {
  SectionTitle: {
    small: "container px-5 sm:px-6 md:px-5  mb-16",
    large: "lg:px-24 xl:px-20",
  },
  Title: {
    all: "frk-bold text-primary",
    small:
      "text-2xl mb-8 pt-40 mr-0 sm:pt-24 sm:text-3xl md:w-8/12 md:pr-2 md:mb-8",
    large: "lg:w-11/12 lg:pt-40 lg:text-5xl xl:text-6xl xl:w-10/12",
  },
  Banner: {
    all: "w-full",
  },
  TeamCont: {
    small: "mt-4  md:w-full md:flex md:mt-8",
    large: "lg:mt-10 ",
  },
  MiddleHyphenCont: {
    small: "flex sm:mb-3 md:w-9/12 md:self-start md:justify-start ",
    large: "xl:w-8/12 xl:pl-2",
  },
  MiddleHyphenContFounder: {
    small: "flex sm:mb-3 md:w-9/12 md:self-start md:justify-start ",
    large: "xl:w-4/5 xl:pl-2",
  },
  MiddleHyphen: {
    small: "bg-primary w-8 h-1 self-center sm:w-2/12 md:w-10 md:mr-4",
    large: "lg:mr-2 xl:mr-4",
  },
  MiddleHyphenTitle: {
    all: "frk-bold text-primary",
    small: "w-9/12 sm:w-8/12 text-2xl text-center md:hidden",
    large: "lg:w-10/12 lg:text-left lg:block ",
  },
  MiddleHyphenTitleMd: {
    all: "frk-bold text-primary  w-8/12 text-2xl hidden md:block lg:hidden",
  },
  TeamTextCont: {
    all: "mt-3 frk-regular text-secondary leading-relaxed md:mt-0",
    small: "text-lg ",
    large: "lg:ml-12 ",
  },
  TeamOurValues: {
    small: "frk-bold my-4  sm:mt-5",
    large: "",
  },
  TeamOurValuesUl: {
    small: "list-disc px-5 sm:mt-2 ",
    large: "lg:mt-0",
  },
  TeamOurValuesLi: {
    small: "my-2 sm:my-1",
    large: "",
  },
  FocusSection: {
    all: "w-full bg-primary md:mt-20 lg:mt-10",
  },
  FocusCont: {
    small: "container  px-4 sm:px-6 pt-4 pb-10 md:px-8 md:pt-6 ",
    large: "lg:px-24 lg:pt-8 xl:px-20 ",
  },
  FocusTitle: {
    all: "frk-bold text-aqua",
    small: "text-3xl text-center md:text-4xl",
    large: "",
  },
  FocusImgSection: {
    small: "mt-10 flex content-start flex-wrap justify-between w-full md:mt-12",
    large: "lg:flex-no-wrap lg:mt-16 ",
  },
  FocusImgCont: {
    small: "w-6/12 px-4 mb-10 sm:w-5/12 sm:mx-auto sm:px-10 md:mb-20",
    large: "lg:px-8 lg:mb-10 xl:w-56",
  },
  FocusClassText: {
    all: "frk-regular text-white text-center ",
    small: "text-sm sm:text-lg mt-3",
    large: "lg:text-lg ",
  },
  ShapeCont: {
    all: "absolute hidden sm:block sm:w-full sm:flex sm:justify-end",
  },
  ShapeImg: {
    all: "object-left-bottom  object-cover",
    small: "sm:w-72 sm:h-64 ",
    large: "lg:w-72 lg:h-84  xl:w-84 xl:h-96",
  },
  FounderSection: {
    all: "mt-5",
    small:
      "relative container px-5 mb-20 pt-6 sm:px-6 md:px-5 md:pt-12 flex-col",
    large: "lg:px-24 lg:flex xl:px-20",
  },
  FounderDiv: {
    small: "sm:flex sm:justify-between",
    large: "lg:justify-end",
  },
  FounderCard: {
    small:
      "shadow w-full text-center mt-6 pt-10 sm:w-48p sm:px-0 bg-white flex flex-col ",
    large: "lg:w-45p lg:mt-0 lg:ml-6",
  },
  MiddleHyphenTitleFounder: {
    all: "frk-bold text-primary ",
    small: "w-10/12 text-2xl text-center  md:text-left ",
    large: "",
  },
  FounderImgCont: {
    all: "w-full flex justify-center",
  },
  FounderImg: {
    small: "w-24",
    large: "",
  },
  FounderName: {
    all: "frk-bold text-primary",
    small: "text-lg mt-4 ",
    large: "",
  },
  FounderCO: {
    all: "frk-regular text-secondary leading-tight",
    small: " text-lg",
    large: "",
  },
  FounderCardShadow: {
    all: "xl:p-10 lg:p-10 md:p-10 p-0 shadow xl:mt-3 lg:mt-2 md:mt-1 mt-0",
  },
  SocialCont: {
    small: "w-full flex justify-center my-3 md:my-4",
    large: "",
  },
  SocialImg: {
    small: "w-1/12",
    large: ":px-6",
  },
  SocialRounded: {
    all: "rounded h-auto w-full object-cover",
  },
  FounderText: {
    all: "text-secondary frk-regular",
    small: " text-lg text-center px-2 ",
    large: "",
  },
  AquaLineDiv: {
    all: "self-end flex-1 w-full flex mt-6",
  },
  AquaLine: {
    small: "w-full h-2 bg-aqua self-end  ",
    large: "",
  },
  BorderAquaLine: {
    all: "xl:ml-3 border-b-6 border-aqua",
  },
  TextBuild: {
    all: "mb-6 font-bold text-tertiary text-2xl",
  },
  FondBold: {
    all: "font-bold",
  },
  MarginBottom: {
    all: "mb-5",
  },
  ImageContainerTeam: {
    all: "container mx-auto lg:space-y-0 grid grid-cols-1 md:grid-cols-12 gap-3"
  },
  ColSpan7: {
    all: "col-auto md:col-span-7"
  },
  ColSpan5: {
    all: "col-auto md:col-span-5"
  },
  ImageTeam: {
    all: "object-cover w-full h-auto"
  }
}

const FounderCardComp = ({ name, src, occupation, UrlSocial, text }) => (
  <div className={`${Style.FounderCard.small} ${Style.FounderCard.large} `}>
    <div className={`${Style.FounderImgCont.all}`}>
      <ImageContainer
        container={`${Style.FounderImg.small} ${Style.FounderImg.small}`}
        src={src}
        alt="team banner"
      />
    </div>
    <h3
      className={`${Style.FounderName.all} ${Style.FounderName.small} ${Style.FounderName.large}`}
    >
      {name}
    </h3>
    <p
      className={`${Style.FounderCO.all} ${Style.FounderCO.small} ${Style.FounderCO.large}`}
    >
      {occupation}
    </p>
    <a
      href={`${UrlSocial}`}
      target="_blank"
      rel="noopener noreferrer"
      className={`${Style.SocialCont.small} ${Style.SocialCont.large}`}
    >
      <ImageContainer
        container={`${Style.SocialImg.small} ${Style.SocialImg.large}`}
        src={Linkedin}
        alt="Linkedin"
        className={`${Style.SocialRounded.all}`}
      />
    </a>
    <p
      className={`${Style.FounderText.all}  ${Style.FounderText.small} ${Style.FounderText.large}`}
    >
      {text}
    </p>
    <div className={`${Style.AquaLineDiv.all}`}>
      <div className={`${Style.AquaLine.small} ${Style.AquaLine.large}`} />
    </div>
  </div>
)

const About = () => {
  return (
    <Layout title="About" link="/about">
      <BgAbout bLogoShow="block" />
      <main className="relative">
        <section
          className={`${Style.SectionTitle.small} ${Style.SectionTitle.large}`}
        >
          <h1
            className={`${Style.Title.all} ${Style.Title.small} ${Style.Title.large}`}
          >
            We are an experienced digital product development team
          </h1>
          <div className={`${Style.ImageContainerTeam.all}`}>
            <div className={`${Style.ColSpan7.all}`}>
              <ImageContainer
                container={`${Style.Banner.all}`}
                src={Team1}
                alt="team1 banner"
                className={`${Style.ImageTeam.all}`}
              />
            </div>
            <div className={`${Style.ColSpan5.all}`}>
              <ImageContainer
                container={`${Style.Banner.all}`}
                src={Team2}
                alt="team2 banner"
                className={`${Style.ImageTeam.all} md:pt-13 lg:pt-20`}
              />
            </div>

            <div className={`${Style.ColSpan5.all}`}>
              <ImageContainer
                container={`${Style.Banner.all}`}
                src={Team3}
                alt="team3 banner"
                className={`${Style.ImageTeam.all} lg:pb-10`}
              />
            </div>

            <div className={`${Style.ColSpan7.all}`}>
              <ImageContainer
                container={`${Style.Banner.all}`}
                src={Team4}
                alt="team4 banner"
                className={`${Style.ImageTeam.all} mb-6`}
              />
            </div>
          </div>

          <div className={`${Style.TeamCont.small} ${Style.TeamCont.large}`}>
            <div
              className={`${Style.MiddleHyphenCont.small} ${Style.MiddleHyphenCont.large}`}
            >
              <div
                className={`${Style.MiddleHyphen.small} ${Style.MiddleHyphen.large}`}
              ></div>
              <h2
                className={`${Style.MiddleHyphenTitle.all} ${Style.MiddleHyphenTitle.small} ${Style.MiddleHyphenTitle.large}`}
              >
                TEAM & VALUES
              </h2>
              <h2 className={`${Style.MiddleHyphenTitleMd.all}`}>ABOUT</h2>
            </div>
            <div
              className={`${Style.TeamTextCont.all} ${Style.TeamTextCont.small} ${Style.TeamTextCont.large}`}
            >
              <p>
                Our team is remote-first, but managed with a strong company
                culture. We have our leadership based in Miami, FL, and most of
                our team members in Colombia.
              </p>
              <p
                className={`${Style.TeamOurValues.small} ${Style.TeamOurValues.large}`}
              >
                {" "}
                Our values are:
              </p>
              <ul
                className={`${Style.TeamOurValuesUl.small} ${Style.TeamOurValuesUl.large}`}
              >
                <li
                  className={`${Style.TeamOurValuesLi.small} ${Style.TeamOurValuesLi.large}`}
                >
                  Always be learning.{" "}
                </li>
                <li
                  className={`${Style.TeamOurValuesLi.small} ${Style.TeamOurValuesLi.large}`}
                >
                  Proactive beats reactive.
                </li>
                <li
                  className={`${Style.TeamOurValuesLi.small} ${Style.TeamOurValuesLi.large}`}
                >
                  Quality over quantity.
                </li>
                <li
                  className={`${Style.TeamOurValuesLi.small} ${Style.TeamOurValuesLi.large}`}
                >
                  Team first.
                </li>
                <li
                  className={`${Style.TeamOurValuesLi.small} ${Style.TeamOurValuesLi.large}`}
                >
                  Honesty &amp; integrity.
                </li>
              </ul>
            </div>
          </div>
        </section>
        <section className={`${Style.FocusSection.all}`}>
          <div className={`${Style.FocusCont.small} ${Style.FocusCont.large}`}>
            <h2
              className={`${Style.FocusTitle.all} ${Style.FocusTitle.small} ${Style.FocusTitle.large}`}
            >
              Focus
            </h2>
            <div
              className={`${Style.FocusImgSection.small} ${Style.FocusImgSection.large}`}
            >
              <ImageContainer
                container={`${Style.FocusImgCont.small} ${Style.FocusImgCont.large}`}
                src={Process}
                alt="Process"
                classText={`${Style.FocusClassText.all} ${Style.FocusClassText.small} ${Style.FocusClassText.large}`}
                text="Study and process"
              />
              <ImageContainer
                container={`${Style.FocusImgCont.small} ${Style.FocusImgCont.large}`}
                src={Design}
                alt="Design"
                classText={`${Style.FocusClassText.all} ${Style.FocusClassText.small} ${Style.FocusClassText.large}`}
                text="Design"
              />
              <ImageContainer
                container={`${Style.FocusImgCont.small} ${Style.FocusImgCont.large}`}
                src={Engineering}
                alt="Engineering"
                classText={`${Style.FocusClassText.all} ${Style.FocusClassText.small} ${Style.FocusClassText.large}`}
                text="Engineering"
              />
              <ImageContainer
                container={`${Style.FocusImgCont.small} ${Style.FocusImgCont.large}`}
                src={Growth}
                alt="Growth"
                classText={`${Style.FocusClassText.all} ${Style.FocusClassText.small} ${Style.FocusClassText.large}`}
                text="Growth"
              />
            </div>
          </div>
        </section>
        <ImageContainer
          container={`${Style.ShapeCont.all}`}
          src={Shape2}
          alt="shape2"
          className={`${Style.ShapeImg.all} ${Style.ShapeImg.small} ${Style.ShapeImg.large}`}
        />
        <section
          className={`${Style.FounderSection.small} ${Style.FounderSection.large} ${Style.FounderSection.all}`}
        >
          <div
            className={`${Style.MiddleHyphenCont.small} ${Style.MiddleHyphenCont.large}`}
          >
            <div
              className={`${Style.MiddleHyphen.small} ${Style.MiddleHyphen.large}`}
            ></div>
            <h2
              className={`${Style.MiddleHyphenTitleFounder.all} ${Style.MiddleHyphenTitleFounder.small} ${Style.MiddleHyphenTitleFounder.large}`}
            >
              JOIN THE TEAM
            </h2>
          </div>
          <div
            className={`${Style.MiddleHyphenContFounder.small} ${Style.MiddleHyphenContFounder.large}`}
          >
            <div className={`${Style.FounderCardShadow.all}`}>
              <div
                className={`${Style.TeamTextCont.all} ${Style.TeamTextCont.small} ${Style.TeamTextCont.large}`}
              >
                <p>We are always looking for great talent!</p>
                <p className={`${Style.MarginBottom.all}`}>
                  We hire remote workers, in Colombia and all of Latin America
                  (or wherever you work from).
                </p>
                <p className={`${Style.MarginBottom.all}`}>
                  Our team includes Developers, Project Managers, QA
                  Specialists, Designers, and management positions.
                </p>
                <p className={`${Style.FondBold.all}`}>
                  If you align with our values above, please send your resume
                  to:
                </p>
                <p className={`${Style.TextBuild.all}`}>hr@hellobuild.co</p>
              </div>
              <div className={`${Style.BorderAquaLine.all}`}></div>
            </div>
          </div>
        </section>
      </main>
    </Layout>
  )
}

export default About